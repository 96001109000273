import {Component, Input, TemplateRef} from '@angular/core';
import {Direction} from '../../../@shared/illustration/tail.component';

@Component({
    selector: 'app-card',
    host: {'class': 'card'},
    template: `
        <div class="card-header" *ngIf="headerStyle == 'bar' && (title || headerAddOn)">
            {{title}}<span class="text-muted" *ngIf="subtitle"> - {{subtitle}}</span>
            <div *ngIf="headerAddOn">
                <ng-container *ngTemplateOutlet="headerAddOn"></ng-container>
            </div>
        </div>
        <div class="card-body{{padding || padding == 0 ? ' p-' + padding : ''}}{{center ? ' d-flex flex-column justify-content-center' : ''}}">
            <div class="data-heading d-flex justify-content-between align-items-start" *ngIf="headerStyle == 'body' && (title || headerAddOn || subtitle)">
                <div>
                    <h1 class="title" *ngIf="title">{{title}}</h1>
                    <h3 class="subtitle" *ngIf="subtitle">{{subtitle}}</h3>
                </div>
                <div *ngIf="headerAddOn">
                    <ng-container *ngTemplateOutlet="headerAddOn"></ng-container>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
        <div class="card-footer d-flex align-items-center spacious" *ngIf="footer">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
        </div>
        <app-tail *ngIf="tail" [direction]="tail" [size]="8"></app-tail>
    `,
})
export class CardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() headerAddOn: TemplateRef<any>;
    @Input() headerStyle: 'bar' | 'body' = 'bar';
    @Input() footer: TemplateRef<any>;
    @Input() padding: string | number;
    @Input() center = false;
    @Input() tail: Direction;
}
