import {Component, HostBinding, Input, OnChanges} from '@angular/core';

export type Direction = 'up' | 'left' | 'down' | 'right';

@Component({
    selector: 'app-tail',
    template: `
        <svg [attr.viewBox]="viewBox">
            <path [attr.d]="path"/>
        </svg>
    `,
    styleUrls: ['./tail.component.scss'],
})
export class TailComponent implements OnChanges {
    @Input() direction: Direction = 'left';
    @Input() size = 12;
    w: number;
    h: number;
    path: string;
    viewBox: string;
    private _dimensions = [16, 32];

    @HostBinding('style.width') get width() {
        return `${this.w * this.size / 16}px`;
    }

    @HostBinding('style.height') get height() {
        return `${this.h * this.size / 16}px`;
    }

    @HostBinding('style.margin') get margin() {
        return ['up', 'down'].includes(this.direction) ? `0 ${-this.size}px` : `${-this.size}px 0`;
    }

    @HostBinding('class.up') get isUp() {
        return this.direction == 'up';
    }

    @HostBinding('class.left') get isLeft() {
        return this.direction == 'left';
    }

    @HostBinding('class.down') get isDown() {
        return this.direction == 'down';
    }

    @HostBinding('class.right') get isRight() {
        return this.direction == 'right';
    }

    ngOnInit() {
        if (!this.viewBox) this.processChanges();
    }

    ngOnChanges() {
        this.processChanges();
    }

    processChanges() {
        switch (this.direction) {
            case 'up':
                Object.assign(this, {
                    w: this._dimensions[1],
                    h: this._dimensions[0],
                    path: 'M32,16H0c0,0,0,0,0,0L12,4c2.2-2.2,5.8-2.2,8,0L32,16C32,16,32,16,32,16z',
                });
                break;
            case 'left':
                Object.assign(this, {
                    w: this._dimensions[0],
                    h: this._dimensions[1],
                    path: 'M16,0v32c0,0,0,0,0,0L4,20c-2.2-2.2-2.2-5.8,0-8L16,0C16,0,16,0,16,0z',
                });
                break;
            case 'down':
                Object.assign(this, {
                    w: this._dimensions[1],
                    h: this._dimensions[0],
                    path: 'M0,0h32c0,0,0,0,0,0L20,12c-2.2,2.2-5.8,2.2-8,0L0,0C0,0,0,0,0,0z',
                });
                break;
            default:
                Object.assign(this, {
                    w: this._dimensions[0],
                    h: this._dimensions[1],
                    path: 'M0,32V0c0,0,0,0,0,0l12,12c2.2,2.2,2.2,5.8,0,8L0,32C0,32,0,32,0,32z',
                });
        }
        this.viewBox = `0 0 ${this.w} ${this.h}`;
    }
}
